module.exports = [{
      plugin: require('../plugins/gatsby-theme-material-ui-top-layout/gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../plugins/gatsby-plugin-apollo-client/gatsby-browser.js'),
      options: {"plugins":[],"endpointUrl":"https://qa-hotfix-01.faq.karakuri.ninja/graphql"},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
